import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { PROJECT_FILES_TITLE, StateSetter, useLogAndShowNotification } from '@mid-react-common/common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { browserApiService, ProductsUtils } from 'mid-addin-lib';
import { AllAccBridgeQueryParams, getForgeApiServiceInstance } from 'mid-api-services';
import { MIDAccBridgeFolder, FolderDMPermissionAction, ForgeDMProjectFolder, MetaInfo } from 'mid-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import text from 'revit.text.json';
import { SELECTED_PROJECT_ID } from '@mid-react-common/addins';
import { TreeItem } from '@mid-react-common/addins';
import { useQuery } from '@tanstack/react-query';
import DataContext from 'context/Data/Data.context';

interface UseProductSelectionProps {
  selectedProjectId: string | undefined;
  incomingBridgeFoldersMap?: Map<string, MIDAccBridgeFolder>;
  onProjectOrFolderChange: () => void;
  permissionFilter?: FolderDMPermissionAction;
}
export interface UseProductSelectionState {
  rootFoldersTreeItems: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement: TreeItem | undefined;
  products: DynamicContent[] | undefined;
  incomingAccBridgeProducts: DynamicContent[] | undefined;
  productsLoading: boolean;
  bridgeProductsLoading: boolean;
  productsError: Error | null;
  handleFolderClick: (selectedElement: TreeItem) => void;
  handleProductClick: (contentId?: string) => void;
  selectedProduct: DynamicContent | undefined;
  setSelectedProduct: StateSetter<DynamicContent | undefined>;
  expandedFoldersIds: string[];
  areProductsFromAccBridgeIncomingFolder: boolean;
}

const GET_PROJECT_FOLDERS_KEY = 'getProjectFolders';
const GET_OAUTH2_TOKEN_KEY = 'getOAuth2Token';
const GET_ALL_PRODUCTS_IN_PROJECT_KEY = 'getAllProductsInProject';
const GET_BRIDGE_PRODUCTS = 'getBridgeProducts';
const GET_FOLDER_TREE = 'getFolderTree';

export const SELECTED_FOLDER_ID = 'selectedFolderId';
export const SELECTED_PRODUCT_ID = 'selectedProductId';

const useProductSelection = ({
  selectedProjectId,
  permissionFilter,
  incomingBridgeFoldersMap,
  onProjectOrFolderChange,
}: UseProductSelectionProps): UseProductSelectionState => {
  const { selectedIncomingBridgeDataQueryParams, setSelectedIncomingBridgeDataQueryParams, setProductReleasesList } =
    useContext(DataContext);
  const { enableMultiValuesBackwardsCompatibility } = useFlags();
  const previousProjectId = useRef(sessionStorage.getItem(SELECTED_PROJECT_ID));

  // Folders
  const {
    data: rootProductFolders,
    isPending: rootFoldersLoading,
    error: rootFoldersError,
  } = useQuery({
    queryKey: [GET_PROJECT_FOLDERS_KEY, selectedProjectId],
    queryFn: selectedProjectId
      ? async ({ signal }) => {
          const rootFolders = await getForgeApiServiceInstance().getFolders(
            { projectId: selectedProjectId, permissionFilter },
            signal,
          );

          const foldersTreeItems: TreeItem[] =
            rootFolders.map((folder) => ({
              id: folder.urn,
              label: folder.title,
              value: folder.urn,
              isExpandable: true,
              children: [],
              path: [],
            })) || [];
          setRootFoldersTreeItems(foldersTreeItems);

          return rootFolders;
        }
      : undefined,
    enabled: !!selectedProjectId,
  });

  useLogAndShowNotification(rootFoldersError, text.notificationGetRootFolderFailed);

  const [rootFoldersTreeItems, setRootFoldersTreeItems] = useState<TreeItem[]>([]);
  const [selectedFolderTreeElement, setSelectedFolderTreeElement] = useState<TreeItem | undefined>();
  const [expandedFoldersIds, setExpandedFoldersIds] = useState<string[]>([]);
  const accBridgeIncomingFolder = useMemo(
    () => (selectedFolderTreeElement ? incomingBridgeFoldersMap?.get(selectedFolderTreeElement?.id) : undefined),
    [incomingBridgeFoldersMap, selectedFolderTreeElement],
  );

  // Token to fetch Products
  const { data: token } = useQuery({
    queryKey: [GET_OAUTH2_TOKEN_KEY],
    queryFn: browserApiService.getOAuth2Token,
    enabled: !!selectedProjectId,
  });

  // Products
  const [selectedProduct, setSelectedProduct] = useState<DynamicContent | undefined>();
  const {
    data: products,
    isPending: productsLoading,
    error: productsError,
  } = useQuery({
    queryKey: [GET_ALL_PRODUCTS_IN_PROJECT_KEY, selectedProjectId, enableMultiValuesBackwardsCompatibility],
    queryFn:
      token && selectedProjectId
        ? async ({ signal }) =>
            ProductsUtils.getAllProductsInProject(
              { projectId: selectedProjectId, enableMultiValuesBackwardsCompatibility },
              signal,
            )
        : undefined,
    enabled: !!token && !!selectedProjectId,
  });
  useLogAndShowNotification(productsError, text.notificationGetProductsFailed);

  // Products from Bridge folders
  const {
    data: incomingAccBridgeProducts,
    error: accBridgeProductsError,
    isFetching: accBridgeProductsLoading,
  } = useQuery({
    queryKey: [GET_BRIDGE_PRODUCTS, selectedProjectId, selectedFolderTreeElement?.id],
    queryFn: async ({ signal }) =>
      selectedProjectId && selectedFolderTreeElement
        ? ProductsUtils.getAllProductsInBridgeFolder(
            { projectId: selectedProjectId, targetFolderUrn: selectedFolderTreeElement.id },
            signal,
          )
        : undefined,
    enabled: !!accBridgeIncomingFolder,
  });
  useLogAndShowNotification(accBridgeProductsError, text.notificationGetProductsFailed);

  const mergedProductsWithBridgeProducts = useMemo(
    () => [...(products || []), ...(incomingAccBridgeProducts || [])],
    [incomingAccBridgeProducts, products],
  );

  const getExpandedTreeItems = async (projectId: string, folderUrn: string, signal: AbortSignal): Promise<TreeItem[]> => {
    // returns the folder with sub-folders up to the folder, which 'folderUrn' argument is provided
    const projectFolders = await getForgeApiServiceInstance().getFolderTree(projectId, folderUrn, signal);

    if (!projectFolders.length) {
      return [];
    }

    let expandedPath: string[] = [];

    // extract the env-dependent prefix of the folderUrn (URN)
    const folderUrnPrefix = folderUrn.match(/.*\./)![0];

    let treeItemToSelect: TreeItem | undefined;

    // map to store the folder urn as a key and the full path to this folder as a value
    // how does the path look like: folderUrn1/folderUrn2/Folderurn3, it basically represents a hierarchy of folders
    const pathsMap = new Map<string, string>();

    // recursive conversion of the tree structure of a ForgeDMProjectFolder type to the TreeItem type
    function convertToTreeItems(projectFolders: ForgeDMProjectFolder[], treeItems: TreeItem[] = []): TreeItem[] {
      for (const projectFolder of projectFolders) {
        pathsMap.set(
          projectFolder.urn,
          projectFolder.path
            .split('/')
            // enhance path items with the urnPrefix
            .map((pathItem) => folderUrnPrefix + pathItem)
            .join('/'),
        );

        const treeItem: TreeItem = {
          id: projectFolder.urn,
          isExpandable: true,
          value: projectFolder.urn,
          label: projectFolder.title,
          children: projectFolder.folders.length ? convertToTreeItems(projectFolder.folders) : [],
          path: projectFolder.path
            .split('/')
            .map((pathItem): MetaInfo => ({ id: folderUrnPrefix + pathItem, name: projectFolder.title })),
        };

        treeItems.push(treeItem);

        // check if the currently processed folder is the one that has to be restored (folderUrn), if so, save the
        // expandedPath and save this treeItem for the selection
        if (projectFolder.path.split('/').at(-1) === folderUrn.replaceAll(folderUrnPrefix, '')) {
          expandedPath = projectFolder.path.split('/').map((item) => folderUrnPrefix + item);
          treeItemToSelect = treeItem;
        }
      }

      return treeItems;
    }
    const treeItems = convertToTreeItems(projectFolders);

    // if expandedPath is empty, it means that the Project Files is selected
    if (!expandedPath.length) {
      expandedPath = [treeItems[0].id.toString()];
    }

    // convert expandedPath to the same but with urn prefixes
    // edge case: when Project Files folder is not available, its urn will be in the expandedPath, but pathsMap would
    // not have any info about that. So it has to be filtered out.
    expandedPath = expandedPath
      .filter((expandedPathItem) => pathsMap.has(expandedPathItem))
      .map((expandedItem) => pathsMap.get(expandedItem)!);

    setRootFoldersTreeItems(treeItems);
    setExpandedFoldersIds(expandedPath);
    setSelectedFolderTreeElement(treeItemToSelect);

    return treeItems;
  };

  const folderUrnToExpandTree: string | null = useMemo(() => {
    const selectedFolderFromSessionStorage = sessionStorage.getItem(SELECTED_FOLDER_ID);

    return selectedFolderFromSessionStorage
      ? selectedFolderFromSessionStorage
      : rootProductFolders?.at(0)?.title === PROJECT_FILES_TITLE
        ? rootProductFolders[0].urn
        : null;
  }, [rootProductFolders]);

  const isReadyToLoadFolderTree = !!selectedProjectId && !!folderUrnToExpandTree && !rootFoldersLoading;

  // Expand folder tree
  const { error: folderTreeError, isFetching: folderTreeLoading } = useQuery({
    queryKey: [GET_FOLDER_TREE, selectedProjectId, folderUrnToExpandTree],
    queryFn: async ({ signal }) =>
      isReadyToLoadFolderTree ? getExpandedTreeItems(selectedProjectId, folderUrnToExpandTree, signal) : undefined,
    enabled: isReadyToLoadFolderTree,
  });

  // remove selected folder & product from session storage when the project is changed
  useEffect(() => {
    const selectedProjectIdFromSessionStorage = sessionStorage.getItem(SELECTED_PROJECT_ID);
    if (selectedProjectIdFromSessionStorage === previousProjectId.current) {
      return;
    }
    sessionStorage.removeItem(SELECTED_FOLDER_ID);
    sessionStorage.removeItem(SELECTED_PRODUCT_ID);

    setSelectedProduct(undefined);
    setSelectedFolderTreeElement(undefined);
    setRootFoldersTreeItems([]);

    if (onProjectOrFolderChange) {
      onProjectOrFolderChange();
    }

    previousProjectId.current = selectedProjectIdFromSessionStorage;
  }, [selectedProjectId, onProjectOrFolderChange]);

  const handleFolderClick = useCallback(
    (item: TreeItem) => {
      setSelectedFolderTreeElement(item);

      sessionStorage.setItem(SELECTED_FOLDER_ID, item.id);
      sessionStorage.removeItem(SELECTED_PRODUCT_ID);
      setSelectedProduct(undefined);
      setProductReleasesList(undefined);

      if (onProjectOrFolderChange) {
        onProjectOrFolderChange();
      }
    },
    [onProjectOrFolderChange, setProductReleasesList],
  );

  const handleProductClick = (contentId?: string) => {
    if (contentId) {
      const productClicked: DynamicContent | undefined = mergedProductsWithBridgeProducts?.find(
        (product) => product.contentId === contentId,
      );
      if (productClicked) {
        setSelectedProduct(productClicked);
        // persist selected product in session storage
        sessionStorage.setItem(SELECTED_PRODUCT_ID, productClicked.contentId);
      }
    }
  };

  // restore product selection from session storage
  useEffect(() => {
    if (!mergedProductsWithBridgeProducts?.length) {
      return;
    }

    const selectedProductIdFromSessionStorage = sessionStorage.getItem(SELECTED_PRODUCT_ID);

    if (selectedProductIdFromSessionStorage) {
      const productToSelect = mergedProductsWithBridgeProducts.find(
        ({ contentId }) => contentId === selectedProductIdFromSessionStorage,
      );

      if (productToSelect) {
        setSelectedProduct(productToSelect);
      }
    }
  }, [mergedProductsWithBridgeProducts]);

  // This useEffect sets the ACC bridge query params needed
  // in the rest of the app for all bridge compatible endpoints
  useEffect(() => {
    // When the user changes folders (bridge or non-bridge), we need to
    // reset the selectedIncomingBridgeDataQueryParams
    if (
      selectedFolderTreeElement?.id !== selectedIncomingBridgeDataQueryParams?.targetFolderUrn &&
      !!selectedIncomingBridgeDataQueryParams
    ) {
      setSelectedIncomingBridgeDataQueryParams(undefined);
    }

    // The complete ACC Bridge query params can only be set
    // if the acc bridge incoming folder /automation data is
    // available and a *** bridge product is selected ***.
    // We update the query params only when the incoming target folder URN
    // is different from the saved target folder URN
    if (
      accBridgeIncomingFolder &&
      selectedProduct &&
      accBridgeIncomingFolder?.targetFolderUrn &&
      accBridgeIncomingFolder?.targetFolderUrn !== selectedIncomingBridgeDataQueryParams?.targetFolderUrn
    ) {
      const accBridgeTargetProjectQueryParams: AllAccBridgeQueryParams = {
        targetFolderUrn: selectedFolderTreeElement?.id,
        targetProjectId: selectedProjectId,
        sourceFolderUrn: selectedProduct.context.workspace.folderPath,
        sourceProjectId: selectedProduct.tenancyId,
      };
      setSelectedIncomingBridgeDataQueryParams(accBridgeTargetProjectQueryParams);
    }
  }, [
    accBridgeIncomingFolder,
    selectedFolderTreeElement?.id,
    selectedIncomingBridgeDataQueryParams,
    selectedProduct,
    selectedProjectId,
    setSelectedIncomingBridgeDataQueryParams,
  ]);

  return {
    products,
    incomingAccBridgeProducts,
    productsLoading: !!selectedProjectId && productsLoading,
    bridgeProductsLoading: accBridgeProductsLoading,
    productsError,
    rootFoldersTreeItems,
    rootFoldersLoading: !!selectedProjectId && (rootFoldersLoading || folderTreeLoading),
    rootFoldersError: rootFoldersError || folderTreeError,
    selectedFolderTreeElement,
    handleFolderClick,
    handleProductClick,
    selectedProduct,
    setSelectedProduct,
    expandedFoldersIds,
    areProductsFromAccBridgeIncomingFolder: !!accBridgeIncomingFolder,
  };
};

export default useProductSelection;
